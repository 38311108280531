import { number, route, string } from 'react-router-typesafe-routes'
import { type RouteConfig } from '@react-router/dev/routes'
import { flatRoutes } from '@react-router/fs-routes'

export default flatRoutes() satisfies RouteConfig

export const ROUTES = {
  AUTH: {
    LOGIN: route({ path: '', searchParams: { error: string() } }),
    LOGOUT_SUCCESS: route({ path: 'auth/logout-success' }),
  },
  SSO: route({
    path: 'sso',
    children: {
      CALLBACK: route({ path: 'callback' }),
    },
  }),
  API: route({
    path: 'api',
    children: {
      AUTH: route({
        path: 'auth',
        children: {
          LOGIN: route({ path: 'login' }),
          LOGOUT: route({ path: 'logout' }),
        },
      }),
    },
  }),
  MS_AUTH: route({
    path: 'ms-auth',
    children: {
      CALLBACK: route({ path: 'callback' }),
    },
  }),
  GOOGLE_AUTH: route({
    path: 'google-auth',
    children: {
      CALLBACK: route({ path: 'callback' }),
    },
  }),
  GOOGLE_AUTH_BUSINESS_CALLBACK: route({
    path: 'google-auth-business-callback',
  }),
  DATA_PRIVACY: route({ path: 'data-privacy' }),
  START: route({ path: 'start' }),
  CHOOSE_SERVICE: route({ path: 'choose-service' }),
  CHOOSE_STORE: route({ path: 'choose-store' }),
  ERP_REDIRECT: route({ path: 'erp-sync' }),
  GOOGLE_BUSINESS_REDIRECT: route({
    path: 'google-business',
  }),
  COMPANY: route({
    path: 'company/:id',
    params: { id: number() },
    children: {
      SETTINGS: route({
        path: 'settings',
        searchParams: { storeId: number() },
        children: {
          EMPLOYEES: route({ path: 'employees' }),
          USER_ABSENCES: route({ path: 'absence' }),
          STORES: route({ path: 'stores' }),
        },
      }),
      ERP: route({ path: 'erp-sync' }),
      GOOGLE_BUSINESS: route({
        path: 'google-business',
        searchParams: { error: string() },
      }),
    },
  }),
  STORE: route({
    path: 'store/:id',
    params: { id: number() },
    children: {
      CALENDAR: route({
        path: 'calendar',
        searchParams: { employeeId: number() },
        children: {
          MY_APPOINTMENTS: route({ path: 'my-appointments' }),
        },
      }),
      NEW_APPOINTMENT: route({
        path: 'new-appointment',
        children: {
          CONFIRM: route({ path: 'confirm' }),
        },
      }),
      APPOINTMENT_DETAILS: route({
        path: 'appointment/:appointmentId',
        params: { appointmentId: number() },
        children: {
          RESCHEDULE: route({
            path: 'reschedule',
            children: {
              CONFIRM: route({ path: 'confirm' }),
            },
          }),
        },
      }),
      ONBOARDING_SUCCESS: route({ path: 'onboarding-success' }),
      STORE_SETTINGS: route({
        path: 'store-settings',
        children: {
          BASIC: route({ path: 'basic-info' }),
          SERVICE_TYPES: route({ path: 'services' }),
          EMPLOYEES: route({ path: 'employees' }),
          ROOMS: route({ path: 'rooms' }),
          GOOGLE_MAPS: route({ path: 'location' }),
        },
      }),
      EMPLOYEE_SETTINGS: route({
        path: 'employee-settings',
        children: {
          MY_WORKING_HOURS: route({ path: 'my-working-hours' }),
        },
      }),
    },
  }),
  EMPLOYEE_SETTINGS: route({
    path: 'employee-settings',
    children: {
      MY_WORKING_HOURS: route({
        path: 'my-working-hours',
        searchParams: {
          storeId: number(),
        },
      }),
    },
  }),
  CALENDAR_SETTINGS: route({
    path: 'calendar-settings',
    children: {
      CALENDAR_SYNC: route({
        path: 'sync',
        searchParams: {
          error: string(),
          deletedSessionsCount: number(),
          storeId: number(),
        },
      }),
    },
  }),
  ONBOARDING: route({
    path: 'onboarding/:storeId',
    params: { storeId: number() },
    children: {
      WELCOME: route({ path: 'welcome' }),
      BASIC_INFO: route({ path: 'basic-info' }),
      SERVICES: route({ path: 'services' }),
      EMPLOYEES: route({ path: 'employees' }),
      ROOMS: route({ path: 'rooms' }),
      GOOGLE_MAPS: route({ path: 'location' }),
    },
  }),
  EMPLOYEE_ONBOARDING: route({
    path: 'employee-onboarding/:storeId',
    params: { storeId: number() },
    children: {
      MY_WORKING_HOURS: route({ path: 'my-working-hours' }),
    },
  }),
}
